import { ActionFunctionArgs, json, LoaderFunctionArgs, MetaFunction } from "@remix-run/cloudflare";
import { ShouldRevalidateFunctionArgs, useLoaderData } from "@remix-run/react";
import TokenPriceList from "~/components/token-price-list";
import { TokenSwap } from "~/components/token-swap";
import { actionSwap, loadSwap } from "~/lib/swap";

export const meta: MetaFunction = () => {
	return [
		{ title: "Access the best trades on Radix with Astrolescent" },
		{
			name: "description",
			content: "Swap any token on Radix with minimal price impact and at the lowest possible cost thanks to our smart algorithm finding the best routes accross many DEXs. Try it yourself!",
		},
		{
			name: "og:image",
			content: "https://astrolescent.com/assets/img/social-sharing/default.png",
		},
	];
};

export function shouldRevalidate({ defaultShouldRevalidate, formMethod }: ShouldRevalidateFunctionArgs) {
	if (formMethod == "POST") {
		return false;
	}

	return defaultShouldRevalidate;
}

export async function loader(args: LoaderFunctionArgs) {
	const { request } = args;
	const url = new URL(request.url);
	const inputTokenAddress = url.searchParams.get("from");
	const outputTokenAddress = url.searchParams.get("to");
	const amount = url.searchParams.get("amount");
	const sendIt = !!url.searchParams.get("sendIt");

	const swapData = await loadSwap(args, inputTokenAddress, outputTokenAddress, amount);

	return json({
		...swapData,
		sendIt
	});
}

export default function SwapPage() {
	const { tokens, routes, manifest, baseToken, quoteToken, priceImpact, fee, error, sendIt } = useLoaderData<typeof loader>();

	return (
		<>
			<div className="mb-8">
				<TokenSwap tokens={tokens} routes={routes} manifest={manifest} baseToken={baseToken} quoteToken={quoteToken} priceImpact={priceImpact} fee={fee} error={error} displayRouting={true} sendIt={sendIt}></TokenSwap>
			</div>

			<div className="py-12">
				<TokenPriceList tokens={tokens}></TokenPriceList>
			</div>
		</>
	);
}

export async function action(args: ActionFunctionArgs) {
	return json(await actionSwap(args));
}
